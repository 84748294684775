<template>
  <layout>
    <div class="search-top">
      <div class="contant">
        <div class="contant-top">
          <div class="contant-logo">
            <img src="@/assets/home/logo.png" alt @click="$router.push('/')" />
            <span>爱玩就上爱租号</span>
          </div>
          <div class="contant-search">
            <input type="text" placeholder="搜你想要的~" v-model="valueList"/>
            <button  @click="goSerach">搜索</button>
          </div>
        </div>
        <div class="contant-bottom">
          <div class="contant-hot">
            <img src="@/assets/home/hot.png" alt />
            <span>热门游戏</span>
             <div class="newClass">
              <hotlist></hotlist>
            </div>
          </div>
          <ul>
            <li @click="toClick(0)">首页</li>
            <li :class="{ activem: isShows == 1 }" @click="toClick(1)">
              爱租号
            </li>
            <li @click="toClick(2)">
              工具下载
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="member">
      <div class="member_box w">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>个人中心</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="mbr_content clearfix">
          <!-- 左侧列表子组件 -->
          <div class="mbr_nav">
            <div class="gr bt">
              <img src="@/assets/member/grzx2.png" alt="" v-if="this.$route.meta.fag == 1" />
              <img src="@/assets/member/grzx1.png" alt="" v-else />
              <router-link :class="{activeTitle: this.$route.meta.fag == 1}" :to="{name: 'personal'}" tag="span">个人中心</router-link>
            </div>
            <div class="zk">
              <div class="zk_box bt">
                <img src="@/assets/member/wszk2.png" alt="" v-if="this.$route.meta.fag == 2"/>
                <img src="@/assets/member/wszk1.png" alt="" v-else/>
                <span :class="{activeTitle: this.$route.meta.fag == 2}">我是租客</span>
              </div>
              <ul class="zcd">
                <!-- <li>我的订单</li> -->
                <router-link :to="{name: 'myOrder'}" tag="li" :class="{activeColor: this.$route.name != 'myOrder'}" v-if="this.$route.name == 'orderdetail'">我的订单</router-link>
                <router-link :to="{name: 'myOrder'}" tag="li" :class="{activeColor: this.$route.name == 'myOrder'}" v-else>我的订单</router-link>
                <router-link :to="{name: 'collection'}" tag="li" :class="{activeColor: this.$route.name == 'collection'}">我的收藏</router-link>
                <router-link :to="{name: 'browse'}" tag="li" :class="{activeColor: this.$route.name == 'browse'}">浏览记录</router-link>
              </ul>
            </div>
            <div class="qb">
              <div class="qb_box bt">
                <img src="@/assets/member/wdqb2.png" alt="" v-if="this.$route.meta.fag == 3"/>
                <img src="@/assets/member/wdqb1.png" alt="" v-else/>
                <span :class="{activeTitle: this.$route.meta.fag == 3}" >我的钱包</span>
              </div>
              <ul class="zcd">
                <router-link :to="{name: 'giftbar'}" tag="li" :class="{activeColor: this.$route.name == 'giftbar'}" >我的红包</router-link>
                <router-link :to="{name: 'capital'}" tag="li"  :class="{activeColor: this.$route.name == 'capital'}" v-if="this.$route.name == 'capital'">资金明细</router-link>
                <router-link :to="{name: 'capital'}" tag="li"  :class="{activeColor: this.$route.name == 'recharge'}"  v-else>资金明细</router-link>
              </ul>
            </div>
            <div class="zh">
              <div class="zh_box bt">
                <img src="@/assets/member/zhaq2.png" alt="" v-if="this.$route.meta.fag == 4"/>
                <img src="@/assets/member/zhaq1.png" alt="" v-else />
                <span :class="{activeTitle: this.$route.meta.fag == 4}">账户安全</span>
              </div>
              <ul class="zcd">
                <router-link :to="{name: 'password'}" tag="li" :class="{activeColor: this.$route.name == 'password'}">修改登录密码</router-link>
                <router-link :to="{name: 'renzheng'}" tag="li" :class="{activeColor: this.$route.name == 'renzheng'}">实名认证</router-link>
              </ul>
            </div>
            <router-link class="wdxx" tag="div" :to="{name: 'message'}">
              <div class="zh_box bt">
                <img src="@/assets/member/wdxx2.png" alt="" v-if="this.$route.meta.fag == 5"/>
                <img src="@/assets/member/wdxx1.png" alt="" v-else/>
                <span :class="{activeTitle: this.$route.meta.fag == 5}">我的消息</span>
              </div>
            </router-link>
            
          </div>
          <!-- 内容区域子组件 -->
          <div class="mbr_rg">
            <router-view></router-view>
            <router-view name="orderdetail"></router-view>
          </div>
        </div>
      </div>
    </div>
    <side-Bar></side-Bar>
  </layout>
</template>

<script>
import layout from "../../components/layout";
import sideBar from "../../components/common/sidebar";
import hotlist from '../../components/hotlist'

export default {
  components: {
    layout,
    sideBar,
    hotlist
  },
  data() {
    return {
      isShows: 1,
      valueList: '',
    };
  },
  created() {
    
  },
  methods:{
    toClick(e) {
      if(e == 0){
         this.$router.push({
          path:'/home'
        })
      }else if(e == 2) {
        this.$router.push({
          path:'/download'
        })
      }else{
        this.$router.push({
          path:'/lobby'
        })
      }
      this.isShows = e;
    },
     goSerach() {
      if(this.valueList){
          this.$router.push({
          path: '/lobby',
          query: {
          key_word: this.valueList
          }
        })
      }
    },
  },
 
};
</script>

<style lang='scss' scoped>
.search-top {
  width: 100%;
  background-color: #fff;
  height: 168px;
  .contant {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    .contant-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 40px;
      height: 86px;
      .contant-logo {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 218px;
          height: 55px;
          margin-right: 18px;
        }
        span {
          font-size: 15px;
        }
      }
      .contant-search {
        width: 480px;
        height: 48px;
        background: #ffffff;
        border: 2px solid #3c7efe;
        border-right: none;
        border-radius: 24px;
        box-sizing: border-box;
        display: flex;

        input {
          width: 100%;
          height: 100%;
          border: none;
          outline-style: none;
          border-radius: 24px;
          text-indent: 2em;
          font-size: 13px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #a2a6b8;
        }
        button {
          position: relative;
          width: 120px;
          height: 48px;
          background: #3c7efe;
          border-radius: 24px;
          color: #ffffff;
          font-size: 18px;
          box-sizing: border-box;
          margin-top: -2px;
          padding-left: 20px;
          cursor: pointer;
        }
        button::after {
          position: absolute;
          left: 16px;
          top: 50%;
          transform: translateY(-50%);
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: url(../../assets/home/ss.png) no-repeat center center;
          background-size: 100% 100%;
        }
      }
    }
    .contant-bottom {
      display: flex;
      .contant-hot {
        width: 200px;
        height: 42px;
        border-radius: 12px 12px 0 0;
        background-color: #3c7efe;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        cursor: pointer;
        &:hover .newClass{
          max-height: 460px;
          transition: max-height ease-in .3s;
        }
        img {
          width: 16px;
          height: 18px;
          margin-right: 12px;
        }
        span {
          font-size: 16px;
          color: #ffffff;
        }
        .newClass{
          position: absolute;
          top: 42px;
          left: 0;
          max-height: 0;
          min-height: 0;
          overflow: hidden;
          transition: max-height ease-out .4s;
          z-index: 99;
        }
      }
      ul {
        display: flex;
        align-items: center;
        li {
          font-size: 14px;
          color: #666;
          padding: 0 30px;
          // height: 100%;
          line-height: 17px;
          position: relative;
          cursor: pointer;
        }
        .activem{
          color: #000;
          font-weight: bold;
        }
        .activem:after {
          position: absolute;
          bottom: -9px;
          left: 50%;
          margin-left: -7px;
          display: block;
          content: "";
          width: 14px;
          height: 3px;
          background-color: #3b7dfd;
        }
      }
    }
  }
}
.w {
  width: 1200px;
  margin: 0 auto;
}
.member {
  width: 100%;
  background-color: #f6f7fa;
  .member_box {
    .el-breadcrumb {
      padding: 20px 0;
    }
    .mbr_content {
      width: 100%;
      padding-bottom: 40px;
      .mbr_nav {
        float: left;
        width: 220px;
        height: 600px;
        padding: 10px 20px;
        box-sizing: border-box;
        background: #ffffff;
        border-radius: 12px;
        cursor: pointer;
        .bt {
          margin-top: 25px;
          font-size: 15px;
          font-weight: 700;
          font-family: Microsoft YaHei;
          text-align: center;
          color: #83899d;
          img {
            margin-right: 12px;
            vertical-align: middle;
            margin-left: -30px;
          }
        }
        .zcd {
          margin-top: 12px;
          border-top: 1px solid #f6f7fa;
            color: #83899d;
          li {
            font-size: 14px;
            width: 100%;
            height: 36px;
            text-align: center;
            line-height: 36px;
          
          }
          li:hover {
            background-color: #eff1f5;
            border-radius: 8px;
          }
        }
      }
      .mbr_rg {
        width: 968px;
        float: right;
       
      }
    }
  }
  //共用样式
  .btt {
    width: 100%;
    height: 65px;
    border-bottom: 1px solid #f6f7fa;
    padding: 25px 0 0 35px;
    .btts {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      span {
        display: inline-block;
        width: 4px;
        height: 16px;
        background: #3c7efe;
        border-radius: 2px;
        margin-right: 10px;
      }
    }
  }
}

// 
.activeTitle{
  
font-size: 15px;
font-family: Microsoft YaHei;
font-weight: bold;
color: #000000;
}
.activeColor{
  color: #3C7EFE;
}
</style>